/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 25번째 로그에서는 ICT 업계, 마켓컬리, 주니어 채용에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.kurly.com/shop/main/index.php"
  }, "마켓컬리 :: 내일의 장보기, 마켓컬리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%A0%95%EB%B3%B4%ED%86%B5%EC%8B%A0%EA%B8%B0%EC%88%A0"
  }, "정보통신기술 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hanbit.co.kr/store/books/look.php?p_code=B9541585895"
  }, "뇌를 자극하는 Java 프로그래밍")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%A0%84%EC%82%AC%EC%A0%81_%EC%9E%90%EC%9B%90_%EA%B4%80%EB%A6%AC"
  }, "전사적 자원 관리 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sap.com/index.html"
  }, "SAP Software Solutions | Business Applications and Technology")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.oracle.com/smb/finance.html"
  }, "Finance for Small-to-Medium Businesses (SMB) | Oracle Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.douzone.com/"
  }, "더존ICT그룹")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ecount.co.kr/"
  }, "ERP 모든 기능 월4만원 | 웹기반 이카운트 ERP")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EA%B5%AD%EC%A0%9C%ED%9A%8C%EA%B3%84%EA%B8%B0%EC%A4%80"
  }, "국제회계기준 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/ABAP"
  }, "ABAP - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.oracle.com/technetwork/developer-tools/forms/overview/index.html"
  }, "Oracle Forms")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.weirdx.io/"
  }, "이상한모임")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kurly.com/shop/main/index.php"
  }, "마켓컬리 :: 내일의 장보기, 마켓컬리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%B0%BD%EA%B3%A0_%EA%B4%80%EB%A6%AC_%EC%8B%9C%EC%8A%A4%ED%85%9C"
  }, "창고 관리 시스템 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://brunch.co.kr/@javajigi/4"
  }, "후배 개발자에게 - 2019년")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://spring.io/"
  }, "Spring")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://spring.io/projects/spring-boot"
  }, "Spring Boot")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.egovframe.go.kr/"
  }, "eGovFrame Portal 온라인 지원 포탈")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Java_version_history"
  }, "Java version history - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.mdtoday.co.kr/mdtoday/index.html?no=294172"
  }, "마켓컬리, ‘살충제 계란’ 파동 이후 계란 판매량 2배 증가")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=0M5ly2ybPaI"
  }, "퀄리티 있게 새벽배송, 마켓컬리 샛별배송 (30s) - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=5BrO8sNtLZQ"
  }, "전지현 효과를 톡톡히 본 네이버 (NAVER)의 1999~2019년도별 CF 광고 모음 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.cubrid.com/"
  }, "CUBRID | Enterprise Open Source DBMS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://m.garden5.com/front/index.asp"
  }, "garden5")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.kurly.com/shop/board/view.php?id=notice&no=64"
  }, "샛별배송 & 택배배송  :: 내일의 장보기, 마켓컬리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.coupang.com/np/promotion/15584?channel=plp_C2"
  }, "로켓프레시 - 쿠팡!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://emart.ssg.com/event/eventDetail.ssg?promId=1100310586"
  }, "NE.O.002 쓱배송 굿모닝 - 이마트몰, 당신과 가장 가까운 이마트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.kurly.com/shop/board/view.php?id=notice&no=385"
  }, "스티로폼 박스, 아이스팩 회수 서비스 안내 :: 내일의 장보기, 마켓컬리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%A0%80%EC%98%A8_%EC%9C%A0%ED%86%B5"
  }, "저온 유통(콜드 체인) - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/business/view/2019/03/178384/"
  }, "GS25, 1600원 반값 택배 내놨다 - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/22732745"
  }, "'1시간 내 배송기사가 집으로'…SK·GS, 주유소 택배 '홈픽' 내놨다")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kurly.com/shop/board/view.php?id=notice&no=456"
  }, "에코박스v2 시범 운영 안내 :: 내일의 장보기, 마켓컬리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://linq.career-tasu.jp/magazine/cookpad/"
  }, "(일본어) 쿡패드 주식회사 - 주방을 갖춘 오피스, 그 진의를 찾아서! Careertasu LINQ 매거진")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ondemandkorea.com/"
  }, "OnDemandKorea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.kurly.com/shop/goods/goods_view.php?&goodsno=3702"
  }, "제주 목초 우유 :: 내일의 장보기, 마켓컬리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.kurly.com/shop/goods/goods_search.php?searched=Y&log=1&skey=all&hid_pr_text=&hid_link_url=&edit=Y&sword=%C0%CC%BA%A3%B8%AE%C4%DA&x=0&y=0"
  }, "검색결과: 이베리코 :: 내일의 장보기, 마켓컬리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.kurly.com/shop/goods/goods_search.php?searched=Y&log=1&skey=all&hid_pr_text=&hid_link_url=&edit=Y&sword=%C0%CC%C0%AF%BD%C4&x=0&y=0"
  }, "검색결과: 이유식 :: 내일의 장보기, 마켓컬리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/events/aws-summit-is-coming/"
  }, "AWS Summit 서울 행사가 시작됩니다! - 2019년 4월 17일-18일")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://deview.kr/2018"
  }, "DEVIEW 2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://naver.me/Gd5kjglo"
  }, "컬리 신사사옥")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.kakaocorp.com/service/KakaoT"
  }, "카카오 T | 카카오")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.rocketpunch.com/companies/jusighoesa-yunibeubug"
  }, "주식회사 모두의캠퍼스(모두의캠퍼스) 기업, 채용, 투자, 뉴스 - 로켓펀치")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.slideshare.net/ssuser67b08e/aws-112573762"
  }, "신입개발자가 스타트업에서 AWS로 어떻게든 살아가는 썰")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Fizz_buzz"
  }, "Fizz buzz - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/dhh/status/834146806594433025"
  }, "DHH on Twitter: \"Hello, my name is David. I would fail to write bubble sort on a whiteboard. ...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://programmers.co.kr/"
  }, "프로그래머스")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
